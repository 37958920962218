import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess, getInterestsDropdown } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const AddMedicalCondition = (props) => {
  const [name, setName] = useState('');
  const [interestArr, setInterestArr] = useState([]);
  const [interest, setInterest] = useState('');


  const addCoupon = async () => {
    props.toggleLoader(true);

    let path = config.siteUrl;
    path = path + '/nri/add-interest-condition';
    var token = localStorage.getItem('token');
    var data = {};
    data = {
      interest_id: interest,
      name: name
    }
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/medical-condition"
          , 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  const getInterest = async () => {
    var temp = []
    var res = await getInterestsDropdown()
    res.data.map((a) => {
      temp.push({
        label: a.name, value: a._id
      })
    })
    setInterestArr(temp)
  }


  useEffect(() => {
    getInterest()
  }, [])
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/medical-condition">Medical Condition </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Add Medical Condition</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Add Coupon</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Type</label>
                              <div>
                                <select className="form-select select-dropdown" aria-label="Default select example"
                                  value={interest} onChange={(e) => setInterest(e.target.value)}
                                >
                                  <option value="" selected>Select Interest</option>
                                  {interestArr && interestArr?.map((item) => {
                                    return <option value={item?.value}>{item?.label}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Name</label>
                              <input type="text" className="form-control" placeholder="Name"
                                onChange={e => setName(e.target.value)} value={name}
                              />
                            </div>
                          </div>

                          {/* <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Quantity </label>
                              <input type="text" className="form-control" placeholder="Quantity"
                                value={quantity}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[0-9]+$/;
                                  if (value.match(regex) || value === "") {
                                    setQuantity(value)
                                  }
                                }}
                              />
                            </div>
                          </div> */}
                          {/* <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Is Public </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setIsPublic(e.target.value)}
                              >
                                <option value="Yes">Yes</option>
                                <option selected Value="No">No</option>
                              </select>
                            </div>
                          </div> */}
                          {/* <div className="col-md-4">
                            <div className="form-group mb-0">
                              <label className="form-control-label" >Expiry Date</label>
                            </div>
                            <div className='date-pickk'>
                              <DatePicker
                                className="basic-form basic-form input"
                                selected={expiry}
                                onChange={(date) => setExpiry(date)}
                                minDate={new Date()}
                                // minDate={last30}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>

                          </div> */}
                        </div>
                        <button className="btn btn-primary mt-4"
                          onClick={addCoupon}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMedicalCondition);



