import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess, getAdminDetail } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const AddHospital = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('')
  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("id")
  const addCoupon = async () => {
    props.toggleLoader(true);

    let path = config.siteUrl;
    path = path + '/add-hospital-admin';
    var token = localStorage.getItem('token');
    var data = {};

    data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    }
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    )
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/hospital"
          , 3000)

      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  const getDetail = async () => {
    var res = await getAdminDetail(term)
    // console.log(res.data)
    setFirstName(res.data.first_name)
    setLastName(res.data.last_name)
    setEmail(res.data.email)
  }
  useEffect(() => {
    if (term != undefined) {
      getDetail()
    }
  }, [term])

  // console.log(props)

  const updateDetail = async () => {
    props.toggleLoader(true);

    let path = config.siteUrl;
    path = path + '/update-admin/' + term;
    var token = localStorage.getItem('token');
    var data = {};

    data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    }
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    )
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/hospital"
          , 3000)

      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/hospital">Hospital Admin </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Add Hospital Admin</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Add Hospital Admin</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >First Name</label>
                              <input type="text" className="form-control" placeholder="First Name"
                                onChange={e => setFirstName(e.target.value)} value={firstName}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Last Name</label>
                              <input type="text" className="form-control" placeholder="Last Name"
                                onChange={e => setLastName(e.target.value)} value={lastName}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Email</label>
                              <input type="text" className="form-control" placeholder="Email"
                                onChange={e => setEmail(e.target.value)} value={email} disabled={term != undefined ? true : false}
                              />
                            </div>
                          </div>



                          {/* <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setGender(e.target.value)}>
                                <option value="f">f</option>
                                <option selected Value="m">m</option>
                              </select> */}
                        </div>
                        <button className="btn btn-primary"
                          onClick={() => {
                            if (term != undefined) {
                              updateDetail()
                            } else {
                              addCoupon()
                            }
                          }}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddHospital);