import React, { useEffect, useRef, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getCoupons, getNriBookings, getNriProviders, getPhyios } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import config from '../config/config';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';

const Bookings = (props) => {
  const [users, setUsers] = useState([]);
  const [is_delete, setIsDelete] = useState('');
  const [temp_var_id, setTempVarId] = useState('');
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0);
  const [showAudio, setShowAudio] = useState(false);
  const [audio, setAudio] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingId, setPlayingId] = useState(null); // Stores the ID of the playing audio
  const audioRef = useRef(null);

  const togglePlay = (audioUrl, id) => {
    if (playingId === id) {
      // If the same audio is playing, pause it
      audioRef.current.pause();
      setPlayingId(null);
    } else {
      // Stop currently playing audio (if any)
      if (audioRef.current) {
        audioRef.current.pause();
      }
      // Create a new audio instance
      audioRef.current = new Audio(audioUrl);
      audioRef.current.play();
      setPlayingId(id);
    }
  };
  const getList = async () => {
    props.toggleLoader(true);
    var getUsers_var = await getNriBookings(count, page);
    setTotal(getUsers_var?.metadata?.totalPage)
    setPage(getUsers_var?.metadata?.page)
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [])


  const deleteCoupon = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + "/nri/delete-provider/" + temp_var_id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess('Deleted Successfully');
        getList()
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      });
  }


  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var getUsers_var = await getNriBookings(count, selectedPage);
    setTotal(getUsers_var?.metadata?.totalPage)
    setPage(getUsers_var?.metadata?.page)
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  };

  const opengoogleMap = (lat, long) => {
    window.open(`https://www.google.com/maps?q=${lat},${long}`, '_blank');
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Bookings</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-9">
                          <h3 className="mb-0">Booking Listing </h3>
                        </div>
                        {/* <div className="col-3">
                          <h3 className="mb-0 display-end" >
                            <Link to={"/add-providers-nri"} target="_blank">
                              <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>Add Provider</button>
                            </Link></h3>
                        </div> */}
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Booking No.</th>
                            <th>Name</th>
                            <th>Assigned By</th>
                            <th>Patient Name (Address)</th>
                            <th>Interest</th>
                            <th>Problem</th>
                            <th>Condition</th>
                            <th>Phone number</th>
                            <th>Email</th>
                            <th>Booked Date</th>
                            <th>Preferred time</th>
                            <th>Status</th>
                            <th>Payment Status</th>
                            <th>Location</th>
                            {/* <th>Audio</th> */}
                            {/* <th>Description</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <b>{user.booking_number} </b>
                              </td>
                              <td className="table-user">
                                <div style={{ width: '120px' }}>

                                  <b>{user.user?.full_name} </b>
                                </div>
                              </td>

                              <td className="table-user">
                                <div style={{ width: '120px' }}>

                                  <b>{user?.assigned_by?.first_name} {user?.assigned_by?.last_name}</b>
                                </div>
                              </td>
                              <td className="table-user">
                                <div style={{ width: '120px' }}>

                                  <b>{user.member?.name} </b>
                                  <span className="text-muted">( {user?.member?.address}{user?.member?.city && ' ,' + user?.member?.city} {user?.member?.state && ' ,' + user?.member?.state})</span>
                                </div>
                              </td>
                              <td className="table-user">
                                <div style={{ width: '120px' }}>

                                  <span className="text-muted">{user?.interest?.name} </span>
                                </div>
                              </td>
                              <td className="table-user">
                                <div style={{ width: '120px' }}>

                                  <span className="text-muted">{user?.description} </span>
                                </div>
                              </td>
                              <td style={{ width: '12%' }}>
                                <div style={{ width: '120px' }}>

                                  <span className="text-muted">{user.condition?.name} </span>
                                </div>
                              </td>
                              <td className="table-user">
                                <b>{user.user?.phone_number} </b>
                              </td>
                              <td className="table-user">
                                <b>{user.user?.email} </b>
                              </td>
                              <td>
                                <span className="text-muted">{moment(user?.createdAt).format("DD-MM-YYYY")}</span>
                              </td>
                              <td>
                                <div style={{ width: '120px' }}>

                                  <span className="text-muted">{moment(user.preferred_contact_time).format("HH:mm") == 'Invalid date' ? user.preferred_contact_time : moment(user.preferred_contact_time).format("HH:mm")} {user?.user?.time_zone ? `(${user?.user?.time_zone})` : null}</span>

                                </div>
                              </td>
                              <td className="table-user">
                                <b>{user.status} </b>
                              </td>
                              <td className="table-user">
                                <b>{user.payment_status} </b>
                              </td>
                              <td className="table-user" >
                                <div
                                  style={{ width: '100px', color: 'blue', cursor: 'pointer' }}
                                  onClick={() => opengoogleMap(user?.member?.latitude, user?.member?.longitude)}>
                                  <b>View Location</b>
                                </div>
                              </td>
                              {/* <td className="table-user" >
                                <div style={{ width: '100px' }}>

                                   {user.audio_url ? (
                                  <div
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() => togglePlay(user.audio_url, user.id)}
                                  >
                                    <b>{playingId === user.id ? "Pause Audio" : "Play Audio"}</b>
                                  </div>
                                ) : ( 
                                  <span>No Audio</span>
                                   )} 
                                </div> 
                              </td> */}

                              <td>
                                <div style={{ width: '120px' }}>

                                  <Link to={`/view-user/${user?.user_id}`} target="_blank">
                                    <button className="btn btn-warning pull-right mb-1" style={{
                                      marginRight: '5px', padding: '5px 5px',
                                      fontSize: '14px'
                                    }}>View User</button>
                                  </Link>
                                </div>
                                <div style={{ width: '120px' }}>

                                  {user?.status == 'initiated' ?
                                    <Link to={`/assign-provider?id=${user?._id}&interest_id=${user?.interest_id}`} >
                                      <button className="btn btn-primary pull-right" style={{
                                        marginRight: '5px', padding: '5px 5px',
                                        fontSize: '14px'
                                      }}>Assign Provider</button>
                                    </Link>
                                    :
                                    user?.payment_status !== 'completed' &&
                                    <Link to={`/assign-provider?id=${user?._id}&interest_id=${user?.interest_id}&edit=true`} >
                                      <button className="btn btn-secondary pull-right" style={{
                                        marginRight: '5px', padding: '5px 5px',
                                        fontSize: '14px'
                                      }}>Edit</button>
                                    </Link>
                                  }
                                </div>

                              </td>

                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {users.length > 0 &&
                <Row>
                  {/* <Col xs={4}>
                    <div className='mb-1'>
                      <FormattedMessage id="Showing" /> {users?.length} <FormattedMessage id="accountants from page" /> {current_page} <FormattedMessage id="of" /> {pageCount}
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={total}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}
              {/* {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              } */}
            </div>
            {is_delete === true ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title='Are you sure?'
                onConfirm={(e) => {
                  deleteCoupon()
                  setIsDelete(false)
                  // window.location.reload(false)
                }}
                onCancel={(e) => {
                  setIsDelete(false)
                  setTempVarId('')
                }}
                focusCancelBtn
              >
                Do you want to delete this provider?
              </SweetAlert>
            ) : ([]
            )}

            <Modal show={showAudio} onHide={() => setShowAudio(!showAudio)}>

              <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '40px 10px' }}>
                <audio controls>
                  <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </Modal.Body>

            </Modal>
          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
