import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess, getNriInterest, getNriDetails, getHospitalDetails } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import './style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AddHospitalProvider = (props) => {
  const [desc, setDesc] = useState('');
  const [name, setName] = useState('');
  const [interests, setInterests] = useState([]);
  const [interestId, setInterestId] = useState('');
  const [type, setType] = useState('');
  const [years, setYears] = useState('');
  const [yearArr, setYearArr] = useState([])
  const [tags, setTags] = useState([])
  const [languages, setLanguages] = useState([])
  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get("id")
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [file, setFile] = useState('');

  const addProvider = async () => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/hospital/add-provider';
    var token = localStorage.getItem('token');
    let data = new FormData()
    data.append('name', name)
    data.append('type', type)
    data.append('education', tags.toString())
    data.append('experience', years)
    data.append('languages', languages.toString())
    data.append('description', desc)
    data.append('interest_id', interestId)
    data.append('phone_number', phoneNumber)
    data.append('email', email)
    data.append('file', file)
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/hospital-provider"
          , 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  const getYears = () => {
    const yearsArray = [];
    for (let year = 1; year <= 25; year++) {
      yearsArray.push({
        value: year, label: year == 1 ? '1 Year' : `${year.toString()} ${'Years'}`
      });
    }
    setYearArr(yearsArray);
  }


  const getInterest = async () => {
    var res = await getNriInterest()
    setInterests(res.data)
  }
  useEffect(() => {
    getYears()
    getInterest()
  }, [])

  const handleChange = (tags) => {
    setTags(tags)
  }

  const getDetails = async () => {
    var res = await getHospitalDetails(id)
    setName(res.name)
    setDesc(res.description)
    setType(res.type)
    setInterestId(res?.interest_id)
    setYears(res?.experience)
    setEmail(res?.email)
    setPhoneNumber(res?.phone_number)
    var edu = res.education
    var arr = edu.split(',')
    setTags(arr)
    var lang = res.languages
    var array = lang.split(',')
    setLanguages(array)
  }
  useEffect(() => {
    if (id != undefined) {
      getDetails()
    }
  }, [id])

  const updateProvider = async () => {
    props.toggleLoader(true);
    var token = localStorage.getItem('token');
    let data = new FormData()
    data.append('name', name)
    data.append('type', type)
    data.append('education', tags.toString())
    data.append('experience', years)
    data.append('languages', languages.toString())
    data.append('description', desc)
    data.append('interest_id', interestId)
    data.append('phone_number', phoneNumber)
    data.append('email', email)
    data.append('file', file)
    console.log(data)
    let path = config.siteUrl;
    path = path + '/hospital/update-provider/' + id;
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/hospital-provider"
          , 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0])
    }
  };

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            {/* <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link ></li> */}
                            <li className="breadcrumb-item"><Link to="/hospital-provider">Hospital Providers</Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Add Hospital Provider</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Add Hospital Provider</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Name</label>
                              <input type="text" className="form-control" placeholder="Name"
                                onChange={e => setName(e.target.value)} value={name}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Email</label>
                              <input type="text" className="form-control" placeholder="Email"
                                onChange={e => setEmail(e.target.value)} value={email}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Phone Number</label>
                              <PhoneInput
                                country={'in'}
                                onlyCountries={['in']}
                                value={phoneNumber}
                                onChange={phone => setPhoneNumber(phone)}
                                placeholder='Phone number'
                              />
                              {/* <input type="text" className="form-control" placeholder="Phone number"
                                onChange={e => setEmail(e.target.value)} value={email}
                              /> */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Interest</label>
                              <div>
                                <select className="form-select select-dropdown" aria-label="Default select example"
                                  value={interestId} onChange={(e) => setInterestId(e.target.value)}
                                >
                                  <option value="" selected>Select Interest</option>
                                  {interests && interests?.map((item) => {
                                    return <option value={item?._id}>{item?.name}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Type</label>
                              <input type="text" className="form-control" placeholder="Type"
                                value={type}
                                onChange={(e) => {
                                  setType(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Experience</label>
                              <div>
                                <select className="form-select select-dropdown" aria-label="Default select example"
                                  value={years} onChange={(e) => setYears(e.target.value)}
                                >
                                  <option value="" selected>Select Experience</option>
                                  {yearArr && yearArr?.map((item) => {
                                    return <option value={item?.label}>{item?.label}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Education</label>
                              <TagsInput value={tags} onChange={(e) => handleChange(e)} inputProps={{ placeholder: 'Add Education' }} />

                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Languages</label>
                              <TagsInput value={languages} onChange={(e) => setLanguages(e)} inputProps={{ placeholder: 'Add Languages' }} />

                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Description </label>
                              <textarea className="form-control" placeholder='Description' value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Upload Profile Picture </label>
                              <input type="file" accept=".jpg,.jpeg,.png" name="profile_image" onChange={onSelectFile} />
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary"
                          onClick={() => {
                            if (id != undefined) {
                              updateProvider()
                            } else {
                              addProvider()
                            }
                          }}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div >
      </body >
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddHospitalProvider);



