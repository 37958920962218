import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess, getCouponById, getNriCouponById, editFaq } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const EditNriFaq = (props) => {
  const [discount, setDiscount] = useState('');
  const [maxDisc, setMaxDisc] = useState('');
  const [quantity, setQuantity] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [isPublic, setIsPublic] = useState('');
  const [expiry, setExpiry] = useState(new Date());
  const [faqId, setFaqId] = useState('');
  const [discountType, setDiscountType] = useState('percent');


  const getKyc = async () => {
    props.toggleLoader(true)
    const data = await editFaq(props.match.params.id);
    // console.log(data)
    setFaqId(data?._id)
    setQuestion(data?.question)
    setAnswer(data?.answer)
    props.toggleLoader(false)
  }
  useEffect(() => {
    getKyc()
  }, [])


  const addCoupon = async () => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/nri/update-faq/' + faqId;
    var token = localStorage.getItem('token');
    var data = {};

    data = {
      question:question,
      answer:answer
    }
    axios.post(path, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() => window.location.href = "/nri-faqs", 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/nri-coupons">FAQ </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Add Faq</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Add FAQ</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Question</label>
                              <input type="text" className="form-control" placeholder="Code"
                                onChange={e => setQuestion(e.target.value)} value={question}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Answer</label>
                              <input type="text" className="form-control" placeholder="Description"
                                onChange={e => setAnswer(e.target.value)} value={answer}
                              />
                            </div>
                          </div>
                         

                        </div>
                        <button className="btn btn-primary"
                          onClick={addCoupon}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditNriFaq);



