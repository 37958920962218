import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { exportSurvey, getSurveys } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import { usePDF } from "react-to-pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SurveyPdfDocument from './Pdf';
import moment from 'moment';

const SurveyList = (props) => {
  // const targetRef = useRef();
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  const getList = async (search) => {
    if (search == '') {
      props.toggleLoader(true);
    }
    var getUsers_var = await getSurveys(count, page, search);
    setTotal(getUsers_var?.metadata?.totalPage)
    setPage(getUsers_var?.metadata?.page)
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList(search)
  }, [search])

  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var getUsers_var = await getSurveys(count, selectedPage, search);
    setTotal(getUsers_var?.metadata?.totalPage)
    setPage(getUsers_var?.metadata?.page)
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  };

  const exportList = async () => {
    var res = await exportSurvey()
    if (res.status == 200) {
      window.location.href = res.data
    }
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          {/* <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link></li> */}
                          <li className="breadcrumb-item"><a href="#">Survey Listing</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Survey Listing </h3>
                        </div>
                        <div className="col-6">
                          <h3 className="mb-0 display-end" >
                            <input type="text" className="form-control"
                              style={{ width: '35%', marginRight: 10 }}
                              placeholder="Search"
                              value={search}
                              onChange={(e) => {
                                setPage(1)
                                setSearch(e.target.value)
                              }}
                            />
                            <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }} onClick={() => exportList()}>Export</button>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Phone number</th>
                            <th>Email</th>
                            <th>Gender</th>
                            <th>Age</th>
                            <th>Hospital Admin</th>
                            <th>Provider Name</th>
                            <th>Submitted on</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 ? users?.map((user) => (
                            <tr>
                              <td className="table-user" style={{ width: '12%' }}>
                                <b>{user.name} </b>
                              </td>
                              <td className="table-user" >
                                <b>{user.phone_number ? '+' + user.phone_number : ''}  </b>
                              </td>
                              <td className="table-user" >
                                <b>{user.email} </b>
                              </td>

                              <td className="table-user" >
                                <b style={{ textTransform: 'capitalize' }}>{user?.gender} </b>
                              </td>
                              <td className="table-user" >
                                <span >{user.age} </span>
                              </td>



                              <td className="table-user" style={{ width: '13%' }}>
                                <b style={{ textTransform: 'capitalize' }}>{user?.hospital?.first_name} {user?.hospital?.last_name} </b>
                              </td>
                              <td className="table-user" style={{ width: '15%' }}>
                                <b style={{ textTransform: 'capitalize' }}>{user?.provider?.name} </b>
                              </td>
                              <td className="table-user" style={{ width: '15%' }}>
                                <span className="">{moment(user?.createdAt).format('ddd, DD MMM YYYY')} </span>
                              </td>
                              <td style={{ width: '15%' }}>
                                <PDFDownloadLink
                                  document={<SurveyPdfDocument user={user} />}
                                  fileName={`Survey_${user.name}.pdf`}
                                >
                                  <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }} > Download PDF</button>
                                </PDFDownloadLink>
                              </td>
                            </tr>
                          ))
                            :
                            <tr >
                              <td colSpan={10} style={{ textAlign: 'center' }}>
                                No Surveys
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {users.length > 0 &&
                <Row>
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={total}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}
            </div>
          </main>
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);
