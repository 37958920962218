import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getCouponList, getCoupons, getPhyios } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import config from '../config/config';
import axios from 'axios';

const NriCouponListing = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [is_delete, setIsDelete] = useState('');
  const [temp_var_id, setTempVarId] = useState('');

  const getList = async () => {
    props.toggleLoader(true);
    var getUsers_var = await getCouponList(limit, skip);
    setUsers(getUsers_var);
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [skip])

  console.log(users)

  const deleteCoupon = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.delete(path + "/nri/delete-coupon/" + temp_var_id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess('Deleted Successfully');
        getList()
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      });
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Coupons</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-9">
                          <h3 className="mb-0">Coupons Listing </h3>
                        </div>
                        <div className="col-3">
                          <h3 className="mb-0 display-end" >
                            <Link to={"/add-nri-coupon"} >
                              <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>Add coupon</button>
                            </Link></h3>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Code</th>
                            <th>Discount</th>
                            <th>Discount Type</th>
                            <th>Maximum Discount</th>
                            {/* <th> Quantity</th> */}
                            {/* <th>Public</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <b>{user.code} </b>
                              </td>

                              <td className="table-user">
                                <b>{user.discount} </b>
                              </td>
                              <td className="table-user">
                                <b>{user.discount_type} </b>
                              </td>

                              <td>
                                <span className="text-muted">{user.maximum_discount} </span>
                              </td>

                              {/* <td className="table-user">
                                <b style={{ textTransform: 'capitalize' }} >{user.quantity} </b>
                              </td>
                              <td>
                                <span className="text-muted">{user.is_public == true ? 'Yes' : 'No'} </span>
                              </td> */}
                              <td>
                                <Link to={"/edit-nri-coupon/" + user._id} >
                                  <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>Edit</button>
                                </Link>
                                {/* <Link to={"/view-kyc/" + user._id}> */}
                                <button className="btn btn-danger pull-right "
                                  onClick={e => {
                                    setTempVarId(user._id)
                                    setIsDelete(true)
                                  }}>Delete</button>
                                {/* </Link> */}
                              </td>

                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              }
            </div>
            {is_delete === true ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title='Are you sure?'
                onConfirm={(e) => {
                  deleteCoupon()
                  setIsDelete(false)
                  // window.location.reload(false)
                }}
                onCancel={(e) => {
                  setIsDelete(false)
                  setTempVarId('')
                }}
                focusCancelBtn
              >
                Do you want to delete this coupon?
              </SweetAlert>
            ) : ([]
            )}

          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NriCouponListing);
