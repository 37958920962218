import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { exportUser, getUsers, displaySuccess, displayError, getAdmins, deleteAdmin } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import config from '../config/config';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';

const Operations = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(1);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');
  const [sort, setSort] = useState('desc');
  const [is_delete, setIsDelete] = useState('');
  const [userId, setUserId] = useState('');

  const getList = async (search) => {
    props.toggleLoader(true);
    var getUsers_var = await getAdmins(limit, skip);
    setTotal(getUsers_var.metadata.totalCount)
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  }

  const deleteUser = async () => {
    props.toggleLoader(true);
    var res = await deleteAdmin(userId)
    displaySuccess(res.message)
    getList()
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [skip])

  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected;
    setSkip(selectedPage * limit)
    // getList(search)

    props.toggleLoader(false);
  };
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Operational Admins</a></li>
                        </ol>
                      </nav>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Operational Admin Listing ({total})</h3>
                        </div>
                        <div className='col-6 display-end' >
                          {/* <input type="text" className="form-control"
                            style={{ width: '35%', marginRight: 10 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSkip(0)
                              setPageCount(1)
                              setSearch(e.target.value)
                              // const value = e.target.value;
                              // const regex = /^[0-9]+$/;
                              // if (value.match(regex) || value === "") {
                              //   setQuantity(value)
                              // }
                            }}
                          /> */}
                          <Link to={"/add-admin"} >
                            <button className="btn btn-primary ">Add Admin</button>
                          </Link>
                        </div>
                      </div>
                      {/* <div className='row'>
                        <div className="form-group" style={{ width: '15%' }}>
                          <label className="form-control-label" >Sort</label>
                          <select className="form-control"
                            onChange={e => setSort(e.target.value)}
                            value={sort}
                          >
                            <option value="desc">Date descending</option>
                            <option value="asc">Date ascending</option>
                          </select>
                        </div>
                      </div> */}
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            {/* <th></th> */}
                            <th>Name</th>
                            <th>Email</th>
                            {/* <th>Location</th> */}
                            {/* <th>Phone Number</th> */}
                            <th>Registered Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map((user) => (
                            <tr>
                              {/* <td className="table-user" >
                                {user?.photo_url !== undefined ?
                                  <img src={user?.photo_url} style={{ width: 50, height: 50, borderRadius: 30 }} />
                                  :
                                  <div className='name-letter'>
                                    {user.first_name.charAt(0).toUpperCase()}
                                  </div>}
                              </td> */}
                              <td className="table-user">
                                {/* <Link to={"/user-detail/" + user._id} target="_blank"> */}
                                <b className='name'>{user.first_name}  {user.last_name}</b>
                                {/* </Link> */}
                              </td>
                              <td className="table-user">
                                <b className='name2'>{user.email} </b>
                              </td>
                              {/* <td className="table-user">
                                <span className="text-muted address">{user.address} </span>
                              </td> */}
                              {/* <td>
                                <span className="text-muted date">{user.phone_number} </span>
                              </td> */}
                              <td>
                                {user?.createdAt !== undefined ?
                                  <span className="text-muted date">{moment(user.createdAt).format('ddd, DD MMM YYYY')} </span>
                                  :
                                  <span className="text-muted date">{moment(user.created_at).format('ddd, DD MMM YYYY')} </span>}
                              </td>
                              <td className="td-wrapper" style={{ justifyContent: 'flex-start' }}>
                                <Link to={"/add-admin?id=" + user._id} target="_blank">
                                  <button className="btn btn-primary pull-right ml-2" style={{ width: '100px' }} >Edit</button>
                                </Link>
                                <button className="btn btn-danger pull-right" style={{ width: '100px' }} onClick={() => {
                                  setIsDelete(true)
                                  setUserId(user?._id)
                                }}>Delete</button>
                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* {users.length > 0 &&
                <Row>
                 
              <Col xs={12}>
                <div className='paginate-css'>
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    // subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </Col>
            </Row>} */}

              {/* {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              } */}
            </div>
            {is_delete === true ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title='Are you sure?'
                onConfirm={(e) => {
                  deleteUser()
                  setIsDelete(false)
                  // window.location.reload(false)
                }}
                onCancel={(e) => {
                  setIsDelete(false)
                  setUserId('')
                }}
                focusCancelBtn
              >
                Do you want to delete this admin?
              </SweetAlert>
            ) : ([]
            )}
          </main>
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Operations);
