import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getCoupons, getHospitalProviders, getNriProviders, getPhyios } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import config from '../config/config';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';

const HospitalProvider = (props) => {
  const [users, setUsers] = useState([]);
  const [is_delete, setIsDelete] = useState('');
  const [temp_var_id, setTempVarId] = useState('');
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0);

  const getList = async () => {
    props.toggleLoader(true);
    var getUsers_var = await getHospitalProviders(count, page);
    setTotal(getUsers_var?.metadata?.totalPage)
    setPage(getUsers_var?.metadata?.page)
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [])


  const deleteCoupon = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + "/hospital/delete-provider/" + temp_var_id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess('Deleted Successfully');
        setTimeout(() =>
          getList()
          , 1500)
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      });
  }


  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var getUsers_var = await getNriProviders(count, selectedPage);
    setTotal(getUsers_var?.metadata?.totalPage)
    setPage(getUsers_var?.metadata?.page)
    setUsers(getUsers_var.data);
    // setSkip(selectedPage * limit)
    // getList(search)

    props.toggleLoader(false);
  };

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          {/* <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link></li> */}
                          <li className="breadcrumb-item"><a href="#">Hospital Providers</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-9">
                          <h3 className="mb-0">Hospital Providers Listing </h3>
                        </div>
                        <div className="col-3">
                          <h3 className="mb-0 display-end" >
                            <Link to={"/add-hospital-provider"} >
                              <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>Add Hospital Provider</button>
                            </Link></h3>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone number</th>
                            <th>Interest</th>
                            <th>Type</th>
                            {/* <th>Education</th> */}
                            {/* <th>Languages</th> */}
                            <th>Experience</th>
                            {/* <th>Description</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 ? users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <b>{user.name} </b>
                              </td>
                              <td className="table-user">
                                <b>{user.email} </b>
                              </td>
                              <td className="table-user">
                                <b>{user.phone_number ? '+' + user.phone_number : ''}  </b>
                              </td>

                              <td className="table-user">
                                <b>{user?.interest.name} </b>
                              </td>
                              <td className="table-user">
                                <b style={{ textTransform: 'capitalize' }}>{user?.type} </b>
                              </td>

                              {/* <td>
                                <span className="text-muted">{user.education} </span>
                              </td> */}
                              <td>
                                <span className="text-muted">{user.experience} </span>
                              </td>


                              <td>
                                <Link to={"/add-hospital-provider?id=" + user._id} >
                                  <button className="btn btn-primary pull-right" style={{ marginRight: '5px' }}>Edit</button>
                                </Link>
                                {/* <Link to={"/view-kyc/" + user._id}> */}
                                <button className="btn btn-danger pull-right "
                                  onClick={e => {
                                    setTempVarId(user._id)
                                    setIsDelete(true)
                                  }}>Delete</button>
                                {/* </Link> */}
                              </td>

                            </tr>
                          ))
                            :
                            <tr >
                              <td colSpan={10} style={{ textAlign: 'center' }}>

                                No providers
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {users.length > 0 &&
                <Row>
                  {/* <Col xs={4}>
                    <div className='mb-1'>
                      <FormattedMessage id="Showing" /> {users?.length} <FormattedMessage id="accountants from page" /> {current_page} <FormattedMessage id="of" /> {pageCount}
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={total}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}
              {/* {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              } */}
            </div>
            {is_delete === true ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title='Are you sure?'
                onConfirm={(e) => {

                  deleteCoupon()
                  setIsDelete(false)
                  // window.location.reload(false)
                }}
                onCancel={(e) => {
                  setIsDelete(false)
                  setTempVarId('')
                }}
                focusCancelBtn
              >
                Do you want to delete this provider?
              </SweetAlert>
            ) : ([]
            )}

          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HospitalProvider);
