import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: { flexDirection: "column", padding: 20, fontSize: 12 },
  section: { marginBottom: 15, paddingBottom: 10, borderBottom: 1, borderColor: "#ddd" },
  title: { fontSize: 20, fontWeight: "bold", textAlign: "center", marginBottom: 10 },
  subtitle: { fontSize: 16, fontWeight: "bold", marginBottom: 15 },
  text: { fontSize: 12, marginBottom: 3 },
  table: { display: "table", width: "100%", borderStyle: "solid", borderWidth: 1, borderColor: "#000", marginTop: 10 },
  tableRow: { flexDirection: "row" },
  tableCol: { borderStyle: "solid", borderWidth: 1, borderColor: "#000", flex: 1, padding: 3, textAlign: "center" },
  bold: { fontWeight: "bold" },
  capital: { fontSize: 12, marginBottom: 3, textTransform: 'capitalize', }
});

const SurveyPdfDocument = ({ user }) => {
  // Ensure goniometer_data exists; otherwise, set a default empty array
  // const goniometerData = user?.goniometer_data || [
  //   { movement: "Flexion", pre_test: "N/A", post_test: "N/A" },
  //   { movement: "Extension", pre_test: "N/A", post_test: "N/A" },
  //   { movement: "Lateral flexion-right", pre_test: "N/A", post_test: "N/A" },
  //   { movement: "Lateral flexion-left", pre_test: "N/A", post_test: "N/A" },
  //   { movement: "Lateral rotation-right", pre_test: "N/A", post_test: "N/A" },
  //   { movement: "Lateral rotation-left", pre_test: "N/A", post_test: "N/A" },
  // ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>DATA COLLECTION FORM</Text>
        </View>

        {/* Demographic Details */}
        <View style={styles.section}>
          <Text style={styles.subtitle}>DEMOGRAPHIC DETAILS</Text>
          <Text style={styles.text}><Text style={styles.bold}>Name:</Text> {user.name || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Age:</Text> {user.age || "N/A"}</Text>
          <Text style={styles.capital}><Text style={styles.bold}>Gender:</Text> {user.gender || "N/A"}</Text>
          <Text style={styles.capital}><Text style={styles.bold}>Occupation:</Text> {user.occupation || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Contact Number:</Text> +{user.phone_number || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Height:</Text> {user.height_foot}.{user?.height_inch}ft</Text>
          <Text style={styles.text}><Text style={styles.bold}>Weight:</Text> {user.weight || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>BMI:</Text> {user.bmi || "N/A"}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.subtitle}>HISTORY COLLECTION</Text>
          <Text style={styles.text}><Text style={styles.bold}>Chief complaints:</Text> {user.symptoms || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Ongoing treatments:</Text> {user.ongoing_treatment || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Any medical conditions before:</Text> {user.medical_conditions_before || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Any current medications or drug allergies :</Text> {user.medication_or_allergies || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Pain Level:</Text> {user.pain_level || "N/A"}</Text>
        </View>
        {user?.survey_criterias.length > 0 && user?.survey_criterias.map((cr, i) => (

          <View style={styles.section}>
            <Text style={styles.subtitle}>Major Criteria {user?.survey_criterias.length > 1 && `(Problem ${i + 1})`}</Text>
            <Text style={styles.text}><Text style={styles.bold}>Major Problem Criteria:</Text> {cr?.major_problem || "N/A"}</Text>
            <Text style={styles.text}><Text style={styles.bold}>Problem Statement:</Text> {cr?.problem_statement || "N/A"}</Text>
            <Text style={styles.text}><Text style={styles.bold}>Pre Test Evaluation:</Text> {cr?.pre_test_evaluation || "N/A"}</Text>
            <Text style={styles.text}><Text style={styles.bold}>Post Test Evaluation :</Text> {cr?.post_test_evaluation || "N/A"}</Text>
            <Text style={styles.text}><Text style={styles.bold}>Overall Results:</Text> {cr?.overall_result || "N/A"}</Text>
          </View>
        ))
        }
        <View style={styles.section}>
          <Text style={styles.subtitle}>Post Service Session Criteria</Text>
          <Text style={styles.text}><Text style={styles.bold}>Positive Result Feedback :</Text> {user.positive_feedback || "N/A"}</Text>
          <Text style={styles.text}><Text style={styles.bold}>Negative Result Feedback :</Text> {user.negative_feedback || "N/A"}</Text>
        </View>

        {/* Goniometer Table
        <View style={styles.section}>
          <Text style={styles.subtitle}>Goniometer - Range of Motion</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCol, styles.bold]}>S.NO</Text>
              <Text style={[styles.tableCol, styles.bold]}>Neck Movements</Text>
              <Text style={[styles.tableCol, styles.bold]}>Pre-Test</Text>
              <Text style={[styles.tableCol, styles.bold]}>Post-Test</Text>
            </View>
 {goniometerData.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCol}>{index + 1}</Text>
                <Text style={styles.tableCol}>{item.movement}</Text>
                <Text style={styles.tableCol}>{item.pre_test}</Text>
                <Text style={styles.tableCol}>{item.post_test}</Text>
              </View>
            ))} 
          </View>
        </View> */}
      </Page>
    </Document>
  );
};

export default SurveyPdfDocument;
